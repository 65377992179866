@tailwind base;

/* Firefox */
* {
    --primary: #4E4E4E;
    --secondary: #2E2E2E;
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
}

p, span{
    user-select: none;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 5px;
}


*::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 12px;
    border: 3px solid var(--primary);
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dark * {
    --primary: #2E2E2E;
    --secondary: #4E4E4E;
}


.dark *::-webkit-scrollbar-track {
    background: var(--primary);
}

.dark *::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-color: var(--primary);
}


@tailwind components;

@layer components {
    .sp_btn {
        @apply inline-flex justify-center items-center rounded text-white text-base uppercase;
        background-color: #06F;
        padding: 6px 12px;
        flex-shrink: 0;
    }
    .sp_btn_warning {
        @apply inline-flex justify-center items-center rounded text-white text-base uppercase;
        background-color: #DC2626;
        padding: 6px 12px;
        flex-shrink: 0;
    }

    .sp_btn:hover {
        background-color: #0154D1;
    }

    .sp_btn:active {
        background-color: #0049B7;
    }

    .sp_btn:focus {
        background-color: #0154D1;
    }

    .sp_btn:disabled {
        background-color: #D8DAE5;
    }

    .sp_btn_sm {
        @apply inline-flex justify-center items-center rounded text-white text-base uppercase;
        background-color: #06F;
        font-size: 10pt;
        padding: 3px 8px;
        flex-shrink: 0;
    }

    .sp_btn_sm:hover {
        background-color: #0154D1;
    }

    .sp_btn_sm:active {
        background-color: #0049B7;
    }

    .sp_btn_sm:focus {
        background-color: #0154D1;
    }

    .sp_btn_sm:disabled {
        background-color: #D8DAE5;
    }

    .sp_btn_s {
        @apply inline-flex justify-center items-center rounded  text-white ;
        background-color: #06F;
        padding: 6px 12px;
        flex-shrink: 0;
    }

    .sp_btn_s:hover {
        background-color: #0154D1;
    }

    .sp_btn_s:active {
        background-color: #0049B7;
    }

    .sp_btn_s:focus {
        background-color: #0154D1;
    }

    .sp_btn_s:disabled {
        background-color: #D8DAE5;
    }

    .sp_select_s {
        @apply w-full;
        box-sizing: border-box;
        display: inline-flex;
        padding: 4px 8px; /* Reduced padding to make the select smaller */
        align-items: center;
        gap: 4px;
        border: 1px solid transparent;
        border-radius: 4px;
        background: #414141;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        color: #ffffff;
        font-size: 0.875rem; /* Reduced font size */
    }

    .sp_select_s:hover {
        border: 1px solid #0154D1;
    }

    .sp_select_s:focus {
        border: 1px solid #E7E3FC;
        box-shadow: 0 0 0 2px #E7E3FC;
        outline: none;
    }

    .sp_select_s:disabled {
        background: #EDEDED;
        color: #A0A0A0;
    }

    .sp_select {
        @apply w-full;
        box-sizing: border-box;
        display: inline-flex;
        padding: 6px 12px;
        align-items: center;
        gap: 4px;
        border: 1px solid black;
        border-radius: 4px;
        background:#ebebeb;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        color: black;
    }

    .dark .sp_select {
        @apply w-full;
        box-sizing: border-box;
        display: inline-flex;
        background:#414141;
        color: white
    }


    .sp_select:hover {
        border: 1px solid #0154D1;
    }

    .sp_select:focus {
        border: 1px solid #E7E3FC;
        box-shadow: 0 0 0 2px #E7E3FC;
        outline: none;
    }

    .sp_select:disabled {
        background: #EDEDED;
        color: #A0A0A0;
    }

    .dark .sp_input {
        background: #414141;
        color: white;
    }


    .sp_input {
        @apply w-full;
        border-radius: 5px;
        border: 0.5px solid black;
        background:#ebebeb;
        color: black;
        padding: 6px 12px;
        align-items: center;
        transition: background-color 0.2s, border-color 0.2s;
    }


    .sp_input:focus {
        border-color: #4A90E2;
        outline: none;
    }

    .sp_input.error {
        border-radius: 4px;
        border: 1px solid #921919;
        background: #414141;
    }

    .sp_input.success {
        border-radius: 4px;
        border: 1px solid #35B932;
        background: #414141;
    }



    .sp_input:focus {
        border-color: #4A90E2;
        outline: none;
    }

    .sp_input.error {
        border-radius: 4px;
        border: 1px solid #921919;
        background: #414141;
    }

    .sp_input.success {
        border-radius: 4px;
        border: 1px solid #35B932;
        background: #414141;
    }

    .sp_textarea {
        @apply w-full;
        border: 0.5px solid rgba(255, 255, 255, 0.20);
        font-size: 10pt;
        border-radius: 2px;
        background: #414141;
        color: white;
        padding: 6px 12px;
        align-items: center;
        transition: background-color 0.2s, border-color 0.2s;
    }

    .sp_textarea:focus {
        border-color: #4A90E2;
        outline: 1px;
    }

}



@tailwind utilities;

.custom-width-transition {
    transition-property: width;
    transition-duration: 700ms;
}

textarea {
    margin: 0;
    border-radius: 0;
    color: #444;
    background-color: transparent;
}

.backdrop {
    overflow: auto;
}

.highlights {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: transparent;
}

mark {
    color: transparent;
    background-color: #d4e9ab; /* or whatever */
}

.backdrop {
    background-color: transparent /* or whatever */
}

.material-symbols-outlined {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

@layer base {
    .icon-light-up {
        @apply text-warning-50;
    }
}

@keyframes pulsate-brightness-with-color-and-blur {
    0%, 100% {
        filter: brightness(1); /* Kein Blur-Effekt */
        background-color: initial; /* Oder eine andere Startfarbe */
        box-shadow: 0 0 0px transparent; /* Kein Schatten am Anfang und am Ende */
    }
    50% {
        filter: brightness(2); /* Hellerer Zustand */
        background-color: rgba(56, 118, 208, 0.2); /* Hellerer Zustand mit Farbwechsel und Blur-Effekt */
        box-shadow: 0 0 20px rgba(56, 118, 208, 0.25); /* Schatten mit Farbwechsel */
    }
}

.pulsating-icon svg {
    filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4)); /* Drop-Schatten um das SVG-Element */
}

.pulsating-icon {
    animation: pulsate-brightness-with-color-and-blur 2s infinite ease-in-out;
}


.red-dot {
    height: 10px;
    width: 10px;
    background-color: #00dbff;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -5px;
    margin-top: -5px;
}

.custom-max-width {
    max-width: 170px;
    width: 100%;
}
.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
    display: block;
    margin-top: -32px!important;
    top: 100%;
    z-index: 50!important;
    left: 85%;
    height: 40px!important;
    border-top-right-radius: 20px!important;
    border-bottom-right-radius: 20px!important;
    padding-top: 9px!important;
}

.ql-bubble .ql-color-picker .ql-picker-options {
    padding: 10px 5px;
    width: 192px!important;
}

.ql-bubble * {
    box-sizing: border-box;
    z-index: 999999 !important;
}

@media screen and (max-width: 600px) {
    .ql-bubble .ql-picker.ql-expanded .ql-picker-options {
        display: block;
        margin-top: 1px !important;
        top: 100%;
        z-index: 50 !important;
        left: -505%;
        height: 40px !important;
        border-radius: 20px 20px 20px 20px !important;
        padding-top: 9px !important;
        padding-left: 15px;
    }
}

/*MV layouts*/

.one_top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
}

.one_top > :first-child {
    grid-column: 1 / -1;
}
.text-systempiloten_yellow-h {
    line-height: 1.42;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 19px;
    margin: 0;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    box-sizing: border-box;
}

.info-icon-xs {
    font-size: 8pt;
}

.info-icon-sm {
    font-size: 10pt;
}

.info-icon-md {
    font-size: 11pt;
}

.info-icon-lg {
    font-size: 14pt;
}

.workflow-button-xs {
    font-size: 10pt;
}

.workflow-button-sm {
    font-size: 14pt;
}

.workflow-button-md {
    font-size: 16pt;
}

.workflow-button-lg {
    font-size: 18pt;
}
